<template>
  <div class="list row px-0 mx-0">
    <div class="col-md-12">
      <div class="input-group mb-3">
        <input
          type="text"
          class="form-control"
          placeholder="Leita..."
          v-model="Name"
        />
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary"
            type="button"
            @click="searchName"
          >
            Leita
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="d-flex">
        <div class="p-2 align-self-center"><h4>Lutir</h4></div>

        <router-link
          to="/add-item"
          class="ml-auto p-2 align-self-center m-3 btn btn-sm btn-success  "
          >Stovna</router-link
        >

        <!-- <button
          class="ml-auto p-2 align-self-center m-3 btn btn-sm btn-danger  "
          @click="removeAllItems"
        >
          Remove All
        </button> -->
      </div>

      <ul class="list-group">
        <li
          class="list-group-item cursor-pointer"
          :class="{ active: index == currentIndex }"
          v-for="(item, index) in items"
          :key="index"
          @click="setActiveItem(item, index)"
        >
          {{ item.Name }}
        </li>
      </ul>
    </div>
    <div class="col-md-4">
      <div v-if="currentItem">
        <h4>Lutur</h4>
        <div>
          <label><strong>Navn:</strong></label> {{ currentItem.Name }}
        </div>
        <div>
          <label><strong>Prísur:</strong></label>
          {{ currentItem.Price }}
        </div>
        <div>
          <label><strong>Vørunummar:</strong></label>
          {{ currentItem.ItemNumber }}
        </div>

        <router-link :to="'/item/' + currentItem.Id" class="badge badge-warning"
          >Broyt</router-link
        >
      </div>
      <!-- <div v-else>
        <br />
        <p>Please click on a Item...</p>
      </div> -->
    </div>
  </div>
</template>

<script>
import ItemDataService from "../../services/ItemDataService";

export default {
  name: "items-list",
  data() {
    return {
      items: [],
      currentItem: null,
      currentIndex: -1,
      Name: "",
    };
  },
  methods: {
    retrieveItems() {
      ItemDataService.getAll()
        .then((response) => {
          this.items = response.data;
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    refreshList() {
      this.retrieveItems();
      this.currentItem = null;
      this.currentIndex = -1;
    },

    setActiveItem(item, index) {
      this.currentItem = item;
      this.currentIndex = item ? index : -1;
    },

    removeAllItems() {
      if (confirm("Are you sure you want to delete All Record!")) {
        ItemDataService.deleteAll()
          .then((response) => {
            console.log(response.data);
            this.refreshList();
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        console.log("closed");
      }
    },

    searchName() {
      ItemDataService.findByName(this.Name)
        .then((response) => {
          this.items = response.data;
          this.setActiveItem(null);
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    this.retrieveItems();
  },
};
</script>

<style>
.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}
</style>
